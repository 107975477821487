import React, { useState } from 'react';

import AddIcon from '@mui/icons-material/AddCircle';
import Autocomplete from '@mui/material/Autocomplete/Autocomplete';
import Avatar from '@mui/material/Avatar/Avatar';
import { IReportData } from '../shared/interfaces';
import ListItem from '@mui/material/ListItem/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText/ListItemText';
import { MatrixService } from '../services/matrixservice';
import Stack from '@mui/material/Stack/Stack';
import TextField from '@mui/material/TextField';

const _matrixService = new MatrixService();
type orgListItem = {
  value:number,
  label:string
};

const OrgSelector: React.FC<{dashboard: IReportData, setEditDash:any, dashChanged:any}> = ({dashboard, setEditDash, dashChanged}) => {

  const dummyOptions:orgListItem = { 
    value:-1,
    label: ""
  };

  const [selectorMode, setSelectorMode] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [orgList, setOrgList] = useState<orgListItem[]>([dummyOptions]);
  const [selectValue, setSelectValue] = useState<orgListItem>(orgList[0]);

  const editClick = () => {
    setSelectorMode(!selectorMode);
  }
  
  const handleAddEditOrg = (edit = false) => {
    setSelectorMode(!selectorMode);
    setInputValue('');

    if(edit){
      const _orgs = dashboard.OrgData ? dashboard.OrgData : dashboard.Organisations ? dashboard.Organisations : [];
      
      let _user = { UserId: -1, Email: "No Email" };
      _matrixService.getCurrentUser()
        .then((data) => {
          _user.UserId = data ? data.id : -2;
          _user.Email = data ? data.email : "Unknown User"

          const editOrg = {
              OrgId: selectValue.value,
              OrgName: selectValue.label,
              OrgUsers: [
                _user
              ]           
            }

          _orgs?.push(editOrg);

          const uniqueOrgs = _orgs.sort((a,b) => { return a.OrgId - b.OrgId}).filter((item, pos, self) => {
            return !pos || item.OrgId !== self[pos - 1].OrgId;
          });

          dashboard.OrgData= uniqueOrgs;
          console.log("New Orgs: ", dashboard.OrgData)

          const newList:orgListItem[] = []

          _orgs?.forEach(_org => {
            newList.push({
              value: _org.OrgId,
              label: _org.OrgName ? _org.OrgName : "Org Not Found"
            });
          });
          
          setSelectValue(dummyOptions);
          setOrgList(newList);
          setEditDash(dashboard);
          dashChanged();
        });
    }
  }

  const getOrgList = (searchStr:string) => {
    const _orgs = dashboard.OrgData ? dashboard.OrgData : dashboard.Organisations ? dashboard.Organisations : [];

    const ids:number[] = [];

    _orgs.forEach(_org => {
      ids.push(_org.OrgId);
    });

    _matrixService.getOrgList(searchStr)
      .then((_orgList) => {
        const selectMap = _orgList.map((selectOption:any)=>{
            return{
              value: selectOption.id,
              label: selectOption.name
            }
        })

        const cleanList = selectMap.filter((item:any)=>{
          return ids.indexOf(item.value) === -1;
        });

        if(ids.indexOf(0) === -1) {
          cleanList.push(
            {value: 0, label: "All Organisations"}
          )
        }

        setOrgList(cleanList);
      })
      .catch(error => {
        console.error("Error on Organisation Retrieval: " + error);
        return [];
      }); 
  }

  const renderOptions = () => {
    if(orgList === undefined) {
      return [dummyOptions];
    }

    return orgList.map((option) => {
      return { label: option.label, value: option.value };
    });
  }

  return (
    <div>
      <div className={!selectorMode ? "tw-pr-2" : "tw-pr-2 tw-hidden"}>
        <ListItem key="new" onClick={() => handleAddEditOrg()}>
          <ListItemAvatar>
            <Avatar>
              <AddIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
          primary="Add organisation"
          />
          </ListItem>
      </div>
      <div className={selectorMode ? "tw-pr-2" : "tw-pr-2 tw-hidden"}>
        <Stack spacing={2} sx={{ width: 300 }}>
          <Autocomplete
            id="add-new-org"
            freeSolo
            value={selectValue}
            onChange={(e:any, newValue: any) => {
              setSelectValue(newValue);
            }}
            onInputChange={(e, newInputValue) => {
              setInputValue(newInputValue)
              getOrgList(inputValue)
            }}
            options={renderOptions()}
            renderInput={(params) => <TextField {...params}
            label="Start Typing Organisation Name..." />}
          />
          <div>
            <button className="tw-underline tw-pr-2" onClick={() => handleAddEditOrg(true)}>Add</button>
            <button className="tw-underline tw-pr-2" onClick={() => editClick()}>Cancel</button>
          </div>
        </Stack>
      </div>
    </div>
  )
}
export default OrgSelector;