import { Button, Menu, MenuItem } from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import LogoutIcon from '@mui/icons-material/Logout';
import { MatrixSettings } from '../../../models/matrixSettings'
import MenuIcon from '@mui/icons-material/Menu';
import React from 'react';

interface Props {
    logout: () => void;
}

export const TopNavigation: React.FC<Props> = (props: Props) => {

    const matrixSettings = new MatrixSettings();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [menuOpen, setMenuOpen] = React.useState<boolean>(false);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        setMenuOpen(true);
    };
    const handleClose = () => {
        setAnchorEl(null);
        setMenuOpen(false);
    };
    const handleSwitch = () => {
        window.location.href = matrixSettings.apiUrl;
    }

    return (
        <div id="powered-div" className="tw-text-white tw-bg-midnight-blue tw-text-sm tw-font-light tw-w-screen tw-flow-root"
            style={{height: "60px"}}>
                <div className="tw-float-left tw-my-2 tw-ml-4 tw-flex">
                    <img className={"tw-h-6"} src="./assets/mb-logo-white.svg" alt="Matrix Booking"/>
                    <span className="tw-ml-2 tw-pl-2 tw-border-l tw-border-l-white tw-border-solid"
                        style={{marginTop:4, marginBottom:5}}>Insight</span>
                </div>
                <div className="tw-float-right tw-my-2 tw-mr-4">
                    <Button
                        id="basic-button"
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        className="tw-font-light tw-normal-case tw-text-white tw-min-w-0"
                        onClick={handleClick}
                    >
                        {menuOpen &&
                            <CloseIcon className="tw-mr-0.5" sx={{ color: "#09D88D"}}></CloseIcon>
                        }
                        {!menuOpen &&
                            <MenuIcon className="tw-mr-0.5" sx={{ color: "#09D88D"}}></MenuIcon>
                        }

                        Menu
                    </Button>
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                        'aria-labelledby': 'basic-button',
                        }}
                    >
                        <MenuItem onClick={handleSwitch}>
                            <div className="tw-flex tw-flex-col tw-ml-2">
                                <div className="tw-flex tw-flex-row">
                                    <img className="tw-h-6 tw-object-cover tw-object-left tw-w-4 tw-mr-2" src="./assets/mb-logo-white.svg" alt="Matrix Booking"/>
                                </div>
                            </div>
                            <div className="tw-flex tw-flex-col tw-mr-16">
                                <div className="tw-flex tw-flex-row">Switch to</div>
                                <div className="tw-flex tw-flex-row tw-font-bold">Matrix Booking</div>
                            </div>
                        </MenuItem>
                        <hr className="tw-mx-3"></hr>
                        <MenuItem>
                            <Button onClick={props.logout} variant="text"
                                startIcon={<LogoutIcon sx={{ color: "#09D88D"}}/>}
                                className="tw-font-light tw-normal-case tw-text-white tw-min-w-0 tw-ml-12"
                                style={{fontSize: "16px"}}>
                                <span className="sm:tw-block tw-text-black">Log out</span>
                            </Button>
                        </MenuItem>
                    </Menu>
                    
                </div>  
        </div>
    );
}