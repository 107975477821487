const configs = {
	"builds": [
		{
			"version": "1.0.0",
			"displayName": "Matrix BI Admin App - Local Instance",
			"description": "In-development build of Matrix BI Platform - Admin App",
			"serverUrl": "https://localhost:9000",
			"outputDir":"build",
			"apiUrl": "https://dev.matrixbooking.com",
			"reportenv": "dev"
		},
		{
			"version": "1.0.0",
			"displayName": "Matrix BI Admin App - Local Instance",
			"description": "In-development build of Matrix BI Platform - Admin App",
			"serverUrl": "https://my.qsapp.net:9001",
			"outputDir":"build",
			"apiUrl": "https://dev.matrixbooking.com",
			"reportenv": "dev"
		},
		{
			"version": "1.0.0",
			"displayName": "Matrix BI",
			"description": "No-nonsense resource booking BI from Matrix Booking.",
			"serverUrl": "https://insight.matrixbooking.com",
			"outputDir":"build",
			"apiUrl": "https://app.matrixbooking.com",
			"reportenv": "prd"
		},
		{
			"version": "1.0.0",
			"displayName": "Matrix BI",
			"description": "No-nonsense resource booking BI from Matrix Booking.",
			"serverUrl": "https://bi-prd.matrixbooking.com",
			"outputDir":"build",
			"apiUrl": "https://app.matrixbooking.com",
			"reportenv": "prd"
		},
		{
			"version": "1.0.0",
			"displayName": "Matrix Insight Admin - Dev",
			"description": "Matrix Insight Admin - Development",
			"serverUrl": "https://admin.dev.bi-npd.matrixbooking.com",
			"outputDir":"build",
			"apiUrl": "https://dev.matrixbooking.com",
			"reportenv": "dev"
		},
		{
			"version": "1.0.0",
			"displayName": "Matrix Insight Admin - Int",
			"description": "Matrix Insight Admin - Integration",
			"serverUrl": "https://admin.int.bi-npd.matrixbooking.com",
			"outputDir":"build",
			"apiUrl": "https://int.matrixbooking.com",
			"reportenv": "int"
		}
		,
		{
			"version": "1.0.0",
			"displayName": "Matrix Insight Admin - Beta",
			"description": "Matrix Insight Admin - Beta",
			"serverUrl": "https://admin.beta.bi-npd.matrixbooking.com",
			"outputDir":"build",
			"apiUrl": "https://beta.matrixbooking.com",
			"reportenv": "beta"
		}
		,
		{
			"version": "1.0.0",
			"displayName": "Matrix Insight Admin",
			"description": "Matrix Insight Admin",
			"serverUrl": "https://insight-admin.matrixbooking.com",
			"outputDir":"build",
			"apiUrl": "https://app.matrixbooking.com",
			"reportenv": "prd"
		}
	]
};
module.exports = { configs };