import React, { useState } from 'react';

import Alert from '@mui/material/Alert';
import { Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Collapse from '@mui/material/Collapse';
import DashList from "./DashList";
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import { Page } from './layout/Page';

const Dashboards: React.FC = () => {

  const [alertOpen, setOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('')

  return (
    <>

      {
        <Page>
          <Collapse in={alertOpen}>
            <Alert
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              sx={{ mb: 2 }}
            >
              {alertMessage}
            </Alert>
          </Collapse>          
          <Box sx={{display: "flex", gap: 2, flexWrap:"wrap" }} id="UserList">    
            <List>
              <DashList setAlertMessage={setAlertMessage} setOpen={setOpen} />
            </List>
          </Box>
        </Page>
      }

    </>
  );
  
}

export default Dashboards;