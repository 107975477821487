import * as moment from "moment";

import { IAuthMethod, IDashOrgUser, ILogin, IReportData, IUser } from "../shared/interfaces";
import axios, { AxiosRequestHeaders } from "axios";

import { MatrixSettings } from "../models/matrixSettings";

const matrixSettings = new MatrixSettings();
const baseUrl = matrixSettings.matrixServerUrl;
const requestHeaders = {
  "Accept": "application/json;odata=verbose",
  "Content-Type": "application/json;odata=verbose",
};
const axiosAuthorisedOptions = { headers: requestHeaders, withCredentials: true };

export class MatrixService {

  // Create a MatrixService with a logout function that is invoked if a 401 error response is received
  constructor(logout?: () => void) {

    //const logoutFunction = logout ?? this.logoutOfMatrix;

    axios.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        // If the error is a 401 and we are not explicitly checking for the existence of a session
        // then log an error and call the logout function to run the login workflow.
        /*
        if (error && error.response && error.response.status === 401) {
          if (error.response.config && error.response.config.url !== baseUrl + "user/session/check") {
            console.error("axios received error response code 401 - invoking logout()");
            logoutFunction();
          }
        }
        */
        return Promise.reject(error);
      });
  }

  async login (login: ILogin): Promise<any> {
    return this.loginUsingMatrix(login)
  }

  async loginUsingMatrix(login: ILogin): Promise<any> {
    
    let returnValue: boolean | Error | null = null;
    login.rememberMe = true;
    let requestUrl = baseUrl + "user/login";

    await axios
      .post(requestUrl, login.credentials, axiosAuthorisedOptions)
      .then(async(response) => {
        console.log(JSON.stringify(response));
        returnValue = true;
      })
      .catch((error: Error) => {
        console.error("Error on Matrix Login: " + error);

        returnValue = error;
      });
    
      return returnValue;
  };

  async getUserToken(): Promise<string> {
    let requestUrl = baseUrl + "user/token/INSIGHT";
    let token = '';

    await axios
      .post(requestUrl, null, axiosAuthorisedOptions)
      .then(response => {
        token = response.data;
      })
      .catch(error => {
          console.error("Error on token retrieve " + error);
      });

      return token;
  }

  async getHeaders():Promise<AxiosRequestHeaders> {

    const _jwt = await this.getUserToken();

    return {
      'Content-Type': 'application/json',
      'authorizationToken': _jwt
    }
  }

  async getDashList(alreadyAdded:any): Promise<IReportData[]> {
    let requestUrl = matrixSettings.awsUrl + "/admin-dashboards";

    if(alreadyAdded.length > 0) {
      requestUrl = requestUrl + "?alreadyRetrieved=" + alreadyAdded.join(",");
    }

    const _axiosHeaders = await this.getHeaders();

    const response = await axios
      .get(requestUrl, {"headers": _axiosHeaders});

    return response.data;
  }

  async reportDashRequest(reportDash:IReportData, httpAction:string): Promise<string> {
    let requestUrl = matrixSettings.awsUrl + "/admin-dashboards";

    const _axiosHeaders = await this.getHeaders();

    let response = { data: 'did not hit switch' };

    switch(httpAction) {
      case "PUT":
        response = await axios.put(requestUrl, reportDash, { "headers": _axiosHeaders });
        break;
      case "DELETE":
        response = await axios.delete(requestUrl, { 
          "headers": _axiosHeaders,
          "data": { 
            "Dashboard": reportDash 
          }
        });
        break;
    }

    return httpAction + ' Operation-OK ' + response.data;
  }

  async reportDashPost(reportDash:IReportData): Promise<IReportData> {
    let requestUrl = matrixSettings.awsUrl + "/admin-dashboards";

    const _axiosHeaders = await this.getHeaders();
    const response = await axios.post(requestUrl, reportDash, { "headers": _axiosHeaders });

    return response.data
  }

  async getReportData(reportTypes:string[]):Promise<IReportData[]> {
    let requestUrl = matrixSettings.awsUrl + "/get-reports-by-account/?reportTypes=";

    let typesParam = "";

    for(let i = 0; i < reportTypes.length; i++) {
      let reportType = reportTypes[i].toLowerCase();

      if(typesParam.length > 0) {
        typesParam = typesParam + "|";
      }

      typesParam = typesParam + reportType;
    }

    const _axiosHeaders = await this.getHeaders();
    
    const response = await axios
      .get(requestUrl + typesParam, { "headers": _axiosHeaders })

    return response.data;

  }

  async getOrgList(partial:string): Promise<any> {

    const _user = await this.getCurrentUser()
    const userId = _user ? _user.id : -1;
    
    let requestUrl = baseUrl + "user/" + userId + "/organisations?partial=" + partial;

    let _response:any = null;
    
    await axios.get(requestUrl, axiosAuthorisedOptions)
          .then(response => {
            console.log("Organisations Received: " + JSON.stringify(response.data));
            _response = response.data;
          })
          .catch(error => {
            console.error("Error on Organisation Retrieval: " + error);
            _response = [];
          });

      return _response;
       
  }

  async getDashUser(email: string): Promise<IDashOrgUser | null> {

    const requestuser = email.toLowerCase();

    const requestUrl = baseUrl + "user/" + requestuser;

    let dashOrgUser: IDashOrgUser|null = null;

    await axios
      .get(requestUrl, axiosAuthorisedOptions)
      .then(response => {
        console.log("Dash User Received: " + JSON.stringify(response.data));
        
        dashOrgUser = {
          UserId: response.data.id,
          Email: requestuser
        }


      })
      .catch(error => {
        console.error("Error on Dash User Retrieval: " + error);
      });

    return dashOrgUser;
  }

  async logoutOfMatrix(): Promise<void> {
    let requestUrl = baseUrl + "user/logout";

    await axios
      .get(requestUrl, axiosAuthorisedOptions)
      .then(response => {
        console.log("Logged Out:" + JSON.stringify(response));
      })
      .catch(error => {
        console.error("Error on Matrix logout: " + error);
      });
  }

  async userSessionCheck(): Promise<IUser | null> {
    var requestUrl = baseUrl + "user/session/check";

    await axios
      .get(requestUrl, axiosAuthorisedOptions)
      .then(response => {
        console.log("Successful authentication via session check:" + JSON.stringify(response));

      })
      .catch(error => {
        console.log("Session not authenticated: " + error);
      });

    return await this.getCurrentUser();
  }

  async getCurrentUser(): Promise<IUser | null> {
    var requestUrl = baseUrl + "user/current?include=defaults";
    let _user: IUser | null = null;

    await axios
      .get(requestUrl, axiosAuthorisedOptions)
      .then(response => {
        console.log("User Received: " + JSON.stringify(response));
        _user = JSON.parse(JSON.stringify(response.data));
      })
      .catch(error => {
        console.error("Error on User Retrieval: " + error);
      });

    return _user;
  };

  async getAuthMethods(username: string): Promise<any> {
    const requestUrl = baseUrl + "user/login/domainCheck";
    let authMethods: IAuthMethod[] = [];
    await axios
      .post(requestUrl, { username: username }, { headers: requestHeaders })
      .then(response => {
        authMethods = response.data.authentications;
      })
      .catch(error => {
        console.error("domainCheck", error);
      });

    return authMethods;
  };

  getAuthURL(username: string, authid: number):string {
    return `${baseUrl}authenticationMethod/${authid}/redirect?username=${username}&RelayState=${window.location.href}`;
  };

  momentToString(d: moment.Moment): string {
    return d.format("YYYY-MM-DD[T]HH:mm");
  }
}
