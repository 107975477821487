import React, { useEffect, useState } from 'react';

import Dashboards from './components/Dashboards';
import { IUser } from "./shared/interfaces";
import Login from './components/Login';
import { MatrixService } from "./services/matrixservice";
import { ScreenSpinner } from './components/shared/ScreenSpinner';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import { TopNavigation } from './components/layout/TopNavigation';
import { theme } from './shared/Theme';

const matrixService = new MatrixService();

const App: React.FC = () => {

  const [authenticated, setAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [currentUser, setCurrentUser] = useState<IUser | null>(null);
  const [isReportUser, setIsReportUser] = useState(false);
  //const [userReports, setUserReports] = useState<string[]>([]);

  const logOut = async () => {
    await matrixService.logoutOfMatrix();
    setAuthenticated(false);
    window.location.href = "./";
  }
  
  if(!authenticated){ 
    matrixService.getCurrentUser().then( async (_user) => {
      
      if(_user){
        const _isServiceAdmin = _user.roles.includes("SERVICE_ADMIN");

        setIsReportUser(_isServiceAdmin);

        if(isReportUser){
          //setUserReports(["ALL"])

          setCurrentUser(_user);
        }
      }

      setLoading(false);
    });
  }
    
  useEffect(() => {
    if(currentUser && currentUser.id > 0) {
      setAuthenticated(true);
    }
    else
    {
      setLoading(true);
    }
  }, [currentUser]);

  return (
    <ThemeProvider theme={theme}>
    <div>
      {authenticated && <TopNavigation logout={logOut}/>}

      <div className="App tw-flex tw-flex-col tw-w-full tw-items-center tw-h-full tw-bg-gainsboro"
          style={{overflow: "auto", maxHeight: "100vh", minWidth: "100%"}}>

        {(authenticated  && isReportUser) &&
          <div className="tw-flow-root tw-w-full tw-overflow-hidden">
            <Dashboards />
          </div>
        }

        {(authenticated  && !isReportUser) &&
          <div className="tw-flow-root tw-w-full">
            <div className="tw-m-8 tw-text-center">
                <span>You do not have access to the admin functions for Matrix Booking Insight.</span>
            </div>
          </div>
        }

        {loading &&
          <ScreenSpinner/>
        }
        
        {!authenticated && !loading &&
          <div className="tw-w-3/4">
            <Login />
          </div>
        }

      </div>
    </div>
    </ThemeProvider>
  );
}

export default App;
