import React, { useState } from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';

const DeleteConfirmationModal: React.FC<{open:boolean, onClose:any, handleDelete:any, deleteTarget:any, deleteName:string}> = ({open, onClose, handleDelete, deleteTarget, deleteName}) => {
  const [inputValue, setInputValue] = useState('');
  const [isDeleteConfirmed, setIsDeleteConfirmed] = useState(false);
  
  const handleInputChange = (event:any) => {
    setInputValue(event.target.value);
    setIsDeleteConfirmed(event.target.value === 'delete');
  };

  const handleConfirm = () => {
    if (isDeleteConfirmed) {
      handleDelete(deleteTarget);
      setInputValue('');
    }
    onClose();
  };

  return (
    <div>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete <b>{deleteName}</b>? If so, type "delete" in the text box and hit OK to proceed.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Type 'delete' to continue"
            fullWidth
            value={inputValue}
            onChange={handleInputChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirm} color="primary" disabled={!isDeleteConfirmed}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default DeleteConfirmationModal;
