import { Button, IconButton } from '@mui/material';
import { IDashOrg, IReportData } from '../shared/interfaces';

import AddIcon from '@mui/icons-material/AddCircle';
import Avatar from '@mui/material/Avatar';
import Dashboard from './Dashboard';
import DashboardIcon from '@mui/icons-material/Dashboard';
import DeleteConfirmationModal from './DeleteConfirmationModal';
import DeleteIcon from '@mui/icons-material/Delete';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import { MatrixService } from '../services/matrixservice';
import React from 'react';
import { SpinnerContainer } from "./shared/SpinnerContainer";

const _matrixService = new MatrixService();

const DashList: React.FC<{setAlertMessage:any, setOpen:any}> = ({setAlertMessage, setOpen}) => {

  const [dashboards, setDashboards] = React.useState<IReportData[]>([]);
  const _dummyDash: IReportData = { DashboardId:'' ,ReportName:'', ReportType:'', ReportAccount: ''};
  const [loading, setLoading] = React.useState(true);
  const [addEdit, setAddEdit] = React.useState(false);
  const [title, setTitle] = React.useState('');
  const [currentDash, setCurrentDash] = React.useState<IReportData>(_dummyDash);
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const [allDashesRetrieved, setAllDashesRetrieved] = React.useState(false);
  const [firstDashCall, setFirstDashCall] = React.useState(true);

  const getDashes = async () => {

    const loadedDashes:any = []

    if(dashboards.length > 0) {
      dashboards.forEach((dash) => {
        loadedDashes.push(dash.DashboardId);
      });
    }

    const dashData = await _matrixService.getDashList(loadedDashes);

    if(dashData.length < 5 || dashData.length === 0){
      setAllDashesRetrieved(true);
      setAlertMessage("All Dashes have been retrieved.");
      setOpen(true);
    }

    let newDashes = dashboards.length > 0 ? dashboards.concat(dashData) : dashData;

    setDashboards(newDashes.sort((a,b)=>{
      return a.ReportName.localeCompare(b.ReportName);
    }));

    setLoading(false);
  }

  const dashesUpdated = (message:string, fromButton:boolean = false) => {
      if(message.length > 0) {
        setAlertMessage(message);
        setOpen(true);
      }

      if(firstDashCall || fromButton) {
        setFirstDashCall(false);
        getDashes().then(()=>{
          setLoading(false);
        });
      }
  }

  if(!loading && !addEdit && dashboards.length === 0) {
    dashesUpdated('');
  }

  if(loading && !addEdit) {
    dashesUpdated('');
  }

  const dashAction = async (dashboard: IReportData, httpAction:string, newDash:boolean = false) => {
    await setCurrentDash(dashboard);

    if(newDash) {
      dashboards.push(dashboard);
    } else {
      const newDashList = dashboards.filter((dash)=>{ return dash.DashboardId !== dashboard.DashboardId });

      if(httpAction !== 'DELETE') {
        newDashList.push(dashboard);
      }

      await setDashboards(newDashList);
    }
    
    const response = await _matrixService.reportDashRequest(dashboard,httpAction);

    dashesUpdated(response);

    handleClose();
  };

  const handleAddEdit = (edit:boolean, dash:IReportData) => {
    setLoading(true);

    console.log("Add/Edit status, Edit: ", edit, " Dash: ", dash.ReportName )    

    if(edit){

      _matrixService.reportDashPost(dash).then((dashData)=>{
        
        console.log("POST begun")

        const parsedOrgs:any = [];

        if(typeof dashData.Organisations !== "undefined") {

          dashData.Organisations.forEach((org:any) => {
            const _org: IDashOrg = {
              OrgId: org[0].OrgId,
              OrgName: org[0].OrgName,
              OrgUsers: org[1],
              OrgSuperUsers: org[2] ? org[2] : []
            }

            parsedOrgs.push(_org)
          });
        };

        currentDash.OrgData = parsedOrgs;
        
        setCurrentDash(dashData);

        setTitle(`Edit Dash - ${dashData.ReportName}`)

        setAddEdit(true);

        setLoading(false);
        
      });
    } else {

      setCurrentDash(dash);

      setTitle("Add New Dashboard")

      setAddEdit(true);

      setLoading(false);

    } 
    
  };

  const handleClose = () => { setAddEdit(false) };
  
  const handleDelete = async (dash:IReportData) => {
    await setCurrentDash(dash);

    dashAction(dash, 'DELETE');
  }

  const openDeleteDialog = (e:React.MouseEvent<HTMLButtonElement>, dash:IReportData) => {

    e.stopPropagation();
    setCurrentDash(dash);
    setModalIsOpen(true);
  }

  const closeDeleteDialog = () => {
    setModalIsOpen(false);
  }

  const createListItem = (dash:IReportData) => {

    return (
          <ListItem key={`${dash.DashboardId}`} onClick={() => handleAddEdit(true, dash)}
            secondaryAction={
              <IconButton edge="end" aria-label="delete" onClick={(e) => { openDeleteDialog(e, dash) }}>
                <DeleteIcon />
              </IconButton>
            }
          >
            <ListItemAvatar>
              <Avatar>
                <DashboardIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={dash.ReportName}
              secondary={dash.ReportType}
            />
          </ListItem>
    )
  }
  
  const createList = (): Array<JSX.Element> => {

    const listItems: Array<JSX.Element> = [];

    const indexLength = dashboards ? dashboards.length : 0;

    for(let i = 0; i < indexLength; i++)
    {
      let dash = dashboards[i];
      listItems.push(createListItem(dash));
    }

    return listItems;
    
  };

  return (
    <>
      {(loading) &&
        <div className="tw-h-screen">
          <div className= "tw-h-4/6 tw-grid tw-w-full tw-place-content-center">
              <SpinnerContainer />
            </div>
        </div>
      }

      { (!loading && !addEdit) &&
        <div>
          <ListItem key="new" onClick={() => handleAddEdit(false, _dummyDash)}>
            <ListItemAvatar>
              <Avatar>
                <AddIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
            primary="Add new dashboard"
            />
          </ListItem>

          {createList()}

          <Button onClick={() => dashesUpdated('Retrieving More Dashboards...', true)} className={allDashesRetrieved ? "tw-pr-2 tw-hidden": "tw-pr-2"}>Load More Dashboards</Button>

          
        </div>   
      }

      { (!loading && addEdit) &&

        <div>
          <div>{title}</div>
          <div>
            <Dashboard dashboard={currentDash} handleClose={handleClose} action={dashAction} />
          </div>
        </div>

      }
      <DeleteConfirmationModal open={modalIsOpen} onClose={closeDeleteDialog} handleDelete={handleDelete} deleteTarget={currentDash} deleteName={currentDash.ReportName} />
    </>
  );
  
}

export default DashList;