import React, { useState } from 'react';

import AddIcon from '@mui/icons-material/AddCircle';
import Avatar from '@mui/material/Avatar/Avatar';
import { IDashOrgUser } from '../shared/interfaces';
import ListItem from '@mui/material/ListItem/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText/ListItemText';
import { MatrixService } from '../services/matrixservice';
import { TextField } from '@mui/material';

const _matrixService = new MatrixService();

const AddUser: React.FC<{orgId: number, usersUpdated:any}> = ({orgId, usersUpdated}) => {

  
  const [addUserMode, setAddUserMode] = useState(false);
  const [userList, setUserList] = useState('');
  
  const editClick = () => {
    setAddUserMode(!addUserMode);
  }
  
  const handleAddUsers = () => {
    setAddUserMode(!addUserMode);
    const emails = userList.split(",").map((a) => a.trim());

    const dashOrgUsers:IDashOrgUser[] = [];

    emails.forEach((email, idx) => {
      _matrixService.getDashUser(email)
        .then((dashOrgUser)=>{

          if(dashOrgUser){
            dashOrgUsers.push(dashOrgUser);
          }

          if (dashOrgUsers.length === emails.length) {
            usersUpdated(orgId, dashOrgUsers);
          }
        });
    });
  }

  return (
    <div>
      <div className={addUserMode ? "tw-pr-2 tw-hidden" : "tw-pr-2"}>
        <ListItem key={"new-users-" + orgId} onClick={() => editClick()}>
          <ListItemAvatar>
            <Avatar>
              <AddIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
          primary="Add user(s)"
          />
          </ListItem>
      </div>
      <div className={addUserMode ? "tw-pr-2" : "tw-pr-2 tw-hidden"}>
        <div>
          <TextField
            placeholder="Add emails separated with commas."
            multiline
            rows={4}
            maxRows={8}
            value={userList}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setUserList(e.target.value);
            }}
          />
        </div>
        <button className="tw-underline tw-pr-2" onClick={() => {handleAddUsers()}} >Add</button>
        <button className="tw-underline tw-pr-2" onClick={() => {editClick()}} >Cancel</button>
      </div>
    </div>
  )
}
export default AddUser;